.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.custom-table th, 
.custom-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--kt-highlight-border);
}

.custom-table tbody tr {
  cursor: pointer;
}

.custom-table tbody tr:hover {
  background-color: var(--kt-highlight-bg);
  color: white;
}

.status-tag {
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600  ;
}

.delivered {
  /* background-color: var(--kt-highlight-btn-bg); */
  /* seccuss color: */
  color: #51cb6d;
}

.warning {
  /* background-color: var(--kt-highlight-btn-bg-hover); */
  color: red;
}

.expanded-row {
  background-color: var(--kt-highlight-scrollbar-color);
  color: white;
}

.expanded-content {
  padding: 10px;
  font-size: 14px;
}

.expanded {
  background-color: var(--kt-highlight-bg);
  color: white;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination ul {
  list-style: none;
  display: flex;
  gap: 10px;
  padding: 0;
}

.pagination li {
  display: inline-block;
}

.pagination button {
  padding: 8px 16px;
  border: 1px solid var(--kt-highlight-border);
  background-color: var(--kt-highlight-btn-bg);
  color: var(--kt-highlight-btn-color);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 4px;
}

.pagination button:hover {
  background-color: var(--kt-highlight-btn-bg-hover);
  color: var(--kt-highlight-btn-color-hover);
}

.pagination li.active button {
  background-color: var(--kt-highlight-scrollbar-color);
  color: var(--kt-highlight-btn-color-hover);
}

.pagination button:disabled {
  background-color: #ddd;
  color: #aaa;
  cursor: not-allowed;
}
