//
// Theme style
//
.apexcharts-toolbar {
    margin: 16px;
    padding: 8px !important;
    background: black;
    border-radius: 34px !important;
}
.apexcharts-menu-icon {
    width: auto !important; 
    /* height: 20px; */
    /* line-height: 24px; */
    height: auto !important;
    line-height: auto !important;
    
}
.aside-menu {
    .items {
        display: none;
    }
    .open .items {
        display: block;
    }
    .module-menu:focus-within .items {
        display: block !important;
        outline: none;
    }
    .module-menu:focus {
        outline: none;
    }
}

// even-larger-badge
.even-larger-badge {
    font-size: 1.25rem !important;
}

.promo-codes {
    .wrapper {
        width: 90%;
        margin: 0 auto;
        max-width: 80rem;
    }
    .fs-3 {
        font-size: 1.5rem;
        color: #fff;
    }
    .cols_ {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .col_ {
        width: calc(33% - 2rem);
        margin: 1rem;
        cursor: pointer;
    }
    .container_pc {
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-perspective: 1000px;
        perspective: 1000px;
    }
    .front,
    .back {
        background-size: cover;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        background-position: center;
        -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
        transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
        -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
        transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
        transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        text-align: center;
        min-height: 280px;
        height: auto;
        border-radius: 10px;
        color: #fff;
        font-size: 1.5rem;
    }
    .back {
        background: #cedce7;
        background: -webkit-linear-gradient(45deg, #cedce7 0%, #596a72 100%);
        background: -o-linear-gradient(45deg, #cedce7 0%, #596a72 100%);
        background: linear-gradient(45deg, #cedce7 0%, #596a72 100%);
    }
    .front:after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        opacity: .6;
        background-color: #000;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: 10px;
    }
    .container_pc .code {
        display: flex;
        background: white;
        color: black;
        padding: 8px;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #797979;
        border-radius: 10px;
        font-family: monospace;
        position: absolute;
        width: calc(100% - 52px);
        bottom: 16px;
        left: 26px;
    }
    .container_pc .code p {
        margin: 0;
    }
    .container_pc .copy {
        border: 0;
        background: rgb(139, 255, 139);
    }
    .fs-11 {
        font-size: 3rem;
    }
    .container_pc .percent {
        font-size: 5rem;
    }
    .container_pc .code p:nth-child(2) {
        display: none;
    }
    .container_pc .code p:first-child {
        display: block;
    }
    .container_pc .copy p:nth-child(2) {
        display: block;
    }
    .container_pc .copy p:first-child {
        display: none;
    }
    .promo-codes-header {
        width: 100%;
        margin: 32px auto;
        display: flex;
    }
    .promo-codes-header input {
        width: 100%;
        max-width: 500px;
        padding: 16px;
        border: 0;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
        font-family: monospace;
        margin: auto;
    }
    .container_pc:hover .front,
    .container_pc:hover .back {
        -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
        transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
        -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
        transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
        transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
    }
    .back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .inner {
        -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
        transform: translateY(-50%) translateZ(60px) scale(0.94);
        top: 50%;
        position: absolute;
        left: 0;
        width: 100%;
        padding: 2rem;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        outline: 1px solid transparent;
        -webkit-perspective: inherit;
        perspective: inherit;
        z-index: 2;
    }
    .container_pc .back {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }
    .container_pc .front {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }
    .container_pc:hover .back {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }
    .container_pc:hover .front {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }
    .front .inner p {
        font-size: 2rem;
        margin-bottom: 2rem;
        position: relative;
    }
    .front .inner p:after {
        content: '';
        width: 4rem;
        height: 2px;
        position: absolute;
        background: #C6D4DF;
        display: block;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -.75rem;
    }
    .front .inner span {
        color: rgba(255, 255, 255, 0.7);
        font-family: 'Montserrat';
        font-weight: 300;
    }
    @media screen and (max-width: 64rem) {
        .col_ {
            width: calc(33.333333% - 2rem);
        }
    }
    @media screen and (max-width: 48rem) {
        .col_ {
            width: calc(50% - 2rem);
        }
    }
    @media screen and (max-width: 32rem) {
        .col_ {
            width: 100%;
            margin: 0 0 2rem 0;
        }
    }
}

// Initialize
@import 'init';
// Components
@import './core/components/components';
@import 'components/components';
// Layout
@import './core/layout/base';
@import 'layout/layout';